<template>
  <v-container fluid>
    <subHeader />
    <!-- {{ enableButton() }} -->
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-tiles/Singapore.Hero.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            We’re thrilled to be partnering with House of Travel and Singapore Tourism Board to bring you the opportunity to win one of nine spots on the Famil of a lifetime!
          </h2>
          <p>
            <b>The Famil prize includes the below for travel 6th November - 13th November 2024.</b>
          </p>
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;">
            <ul>
              <li>
                <b>Return flights on Air New Zealand</b> from Auckland to Singapore in <b>Premium Economy</b>
              </li>
              <li>
                <b>Air New Zealand Lounge access</b> to start the trip off with some luxury in Auckland
              </li>
              <li>
                <b>Accomodation</b> in Singapore
              </li>
              <li>
                <b>Explore Singapore, with unforgettable experiences like:</b>
              </li>
            </ul>
            <v-row>
              <v-col cols="1">
                &nbsp;
              </v-col>
              <v-col cols="5" style="margin-left: -4vw;">
                <ul>
                  <li>Brass Lion Gin Making Class</li>
                  <li>Gardens by the Bay (Flower Dome & Cloud Forest)</li>
                  <li>Singapore Sidecars</li>
                  <li>Mr Bucket Chocolaterie</li>
                </ul>
              </v-col>
              <v-col cols="6" style="margin-left: -4vw; display: flex; align-items: flex-start">
                <ul>
                  <li>Museum of Ice Cream</li>
                  <li>Singapore Flyer</li>
                  <li>Sentosa</li>
                </ul>
              </v-col>
            </v-row>
          </div>
          <!--  -->
              <v-row>
                <v-col
                  v-for="tile in 3"
                  :key="tile.title"
                  cols="4"
                  md="4"
                  sm="12"
                >
                  <v-card tile elevation="0">
                    <v-img
                      :src="$bucket + '/image/dashboard-tiles/Singapore.Image.'+ (tile)+'.jpg'"
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
          <!--  -->
          <p>
            <b>Sounds amazing right? To enter, simply:</b>
          </p>
          <p>
            <ul>
              <li>Log your sales to Singapore made to between September 2nd and September 22nd, 2024, via duosales.</li>
              <li>Complete our Discover Singapore module, brought to you in conjunction with House of Travel and Singapore Tourism Board.</li>
            </ul>
          </p>
          <p>
            <b>Log the highest number of bookings to Singapore in duosales between 2-22 September 2024, plus complete the Discover Singapore module and you could be in to win one of six spots on the famil! If don't manage to log a sale, you still have a chance. A further three winners will be drawn at random. To be eligible to enter you must be registered in duosales and have completed the Discover Singapore module.</b>
          </p>
          <p>
            <b>Winners will be announced on Wednesday, 25th September 2024, via House of Travel.</b>
          </p>
          <p style="font-size: 13px">
            <a
              style="font-size: 13px; color: black; font-style: italic"
              :href="$window.location.origin + '/#/singaporeTC'"
              target="_blank"
              >Terms and conditions apply.</a
            >
          </p>
        </div>
      </v-row>
      
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // tiles: [
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/933adc1b-797e-46cf-8708-d056aa4be62a",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/76403d50-dcd9-4685-a363-4a3d2f0699cc",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/ab2a133b-e3e7-4a84-acf2-2b2f5d00a9a8",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/7a7129e8-1287-48a3-8884-1b3dea847608",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/586b051c-12c2-4aa8-99b2-819bdb1d1480",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/08b45743-5a8b-411d-9892-ca6803d65964",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/07ad4413-5921-4abc-a907-3b944b09baec",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/babfe4d4-b74e-491e-a612-2d966af9ea8c",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/770ba8d0-c72f-483a-bb4b-82e80854bc2b",
      //     button: false,
      //   },
      // ],
      // tile10Button: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    // visit(url, day) {
    //   window.open(url, "_blank");
    //   this.$root.saveActivityLog({
    //     type: "Visit_dashboard_tile",
    //     value: "Fiji Day " + day,
    //   });
    // },
    // enableButton() {
    //   let tzOffset = new Date().getTimezoneOffset() / -60;
    //   let startDate = new Date(Date.UTC(2024, 6, 16, 23, 59, 59));
    //   let dateNow = new Date().getDate();

    //   // eslint-disable-next-line no-console
    //   console.log(new Date(startDate.setHours(startDate.getHours() - tzOffset)), new Date())
      
    //   startDate = new Date(startDate.setHours(startDate.getHours() - tzOffset)).getDate()
      
    //   // eslint-disable-next-line no-console
    //   console.log(startDate, dateNow)

    //   if (dateNow - startDate <= 10) {
    //     for (let i = 1; i <= dateNow - startDate; i++) {
    //       i == 10 ? this.tile10Button = true : this.tiles[i - 1].button = true;
    //     }
    //   } else {
    //     for (let i = 1; i <= 10; i++) {
    //       i == 10 ? this.tile10Button = true : this.tiles[i - 1].button = true;
    //     }
    //   }
    // },
  },
};
</script>
